import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import './TableRow.scss';

const TableRow = ({ items, locale, tdAlign, needTable = false }) => {
  const b = block('adm-bets-table-row');

  return (
    <tr className={b('tr', { needTable })}>
      {items?.map((el, index) => (
        <td key={`${el}_${index}`} className={b('td', { needTable })}>
          <div className={b('td', 'data', { align: tdAlign })}>{el}</div>
        </td>
      ))}
    </tr>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
  tdAlign: PropTypes.string,
  needTable: PropTypes.bool,
};

export default TableRow;
