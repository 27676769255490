import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import block from 'bem-cn';

import './Table.scss';
import TableRow from '../TableRow/TableRow';
import TableHead from '../TableHead/TableHead';
import Paginator from 'components/Paginator';

const b = block('payment-history-table-cash');

const Table = ({ currentPage, onPageClick, onItemsOnPageChanged, isMobile }) => {
  const totalCount = useSelector(state => state.reports.paymentHistory.details.totalCount, shallowEqual);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const paymentHistory = useSelector(state => state.reports.paymentHistory, shallowEqual);

  const rows = useMemo(
    () => paymentHistory.transfers.map(item => <TableRow key={item.id} item={item} locale={locale} />),
    [paymentHistory, locale],
  );

  return (
    <div className={b({ isMobile })}>
      <table className={b('table')}>
        <TableHead locale={locale} isMobile={isMobile} />
        <tbody>{rows}</tbody>
      </table>
      <div className={b('total')}>
        <div
          className={b(
            'total-item',
          )}>{`${locale.total} ${locale.deposit}: ${paymentHistory.details.totalDeposit}`}</div>
        <div
          className={b(
            'total-item',
          )}>{`${locale.total} ${locale.withdrawal}: ${paymentHistory.details.totalWithdraw}`}</div>
      </div>
      <div className={b('paginator-wrapper')}>
        <div className={b('extra-info')}>
          {paymentHistory.details.totalCount} {locale.records}
        </div>

        <Paginator
          count={totalCount}
          currentPage={currentPage}
          onPageClick={onPageClick}
          onItemsOnPageChanged={onItemsOnPageChanged}
          color="white"
        />
      </div>
    </div>
  );
};

Table.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsOnPageChanged: PropTypes.func,
  onPageClick: PropTypes.func.isRequired,
};

export default Table;
