export const tabLinks = [
  {
    name: 'All users',
    link: '/users/all',
  },
  {
    name: 'Agents',
    link: '/users/agents',
  },
  {
    name: 'Players',
    link: '/users/players',
  },
  {
    name: 'Disabled',
    link: '/users/disabled',
  },
];
