import React, { useMemo, useState, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { ModalContext } from 'components/ModalProvider/ModalProvider';

import CreatePlayer from 'features/users/view/desktop/Modals/CreatePlayer/CreatePlayer';
import CreateAgent from 'features/users/view/desktop/Modals/CreateAgent/CreateAgent';

import arrowSvg from './img/arrow.svg';

import './ListItem.scss';

const b = block('list-item');

const ListItem = ({ item, changeMenuOpen, isParent = true }) => {
  const [isOpened, setOpened] = useState(item.children?.length ? false : null);
  const locale = useSelector(state => state.locale.locale);
  const location = useLocation();
  const { openModal, closeModal } = useContext(ModalContext);

  const onClick = useCallback(() => {
    if (item?.children) {
      if (isOpened === null) {
        setOpened(true);
      } else {
        setOpened(prevState => !prevState);
      }
    }
    if (item.title === 'createAgent') {
      openModal({
        title: locale.newAgent,
        content: <CreateAgent closeModal={closeModal} locale={locale} />,
      });
    }
    if (item.title === 'createPlayer') {
      openModal({
        title: locale.newPlayer,
        content: <CreatePlayer closeModal={closeModal} locale={locale} />,
      });
    }

    if (!isParent) changeMenuOpen(false);
  }, [isOpened, item, closeModal, locale, openModal, changeMenuOpen, isParent]);

  const childrens = useMemo(
    () =>
      item.children?.map(el => (
        <ListItem item={el} key={el.title} isParent={false} changeMenuOpen={changeMenuOpen} />
      )) || [],
    [item, changeMenuOpen],
  );

  return (
    <div className={b({ active: location.pathname === item.routeForActiveTab })}>
      <Link to={item.route} className={b('parent', { level: item.level })} onClick={onClick}>
        <div className={b('parent', 'td')}>
          <SVGInline svg={item.img} className={b('parent', 'img-icon').toString()} />
          <span className={b('parent', 'title')}>{locale[item.title] || item.title}</span>
        </div>

        {item?.children ? (
          <SVGInline svg={arrowSvg} className={b('parent', 'img-arrow', { opened: isOpened }).toString()} />
        ) : (
          ''
        )}
      </Link>
      {isOpened && <div className={b('childrens')}>{childrens}</div>}
    </div>
  );
};

export default ListItem;
