import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import block from 'bem-cn';

import './Table.scss';
import TableRow from '../TableRow/TableRow';
import Paginator from 'components/Paginator';

const b = block('users-table');

const Table = ({ locale, currentPage, onPageClick, onItemsOnPageChanged, alignLeft, filterValue }) => {
  const { tab } = useParams();
  const totalCount = useSelector(state => state.users.usersList.totalCount, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);
  const auth = useSelector(state => state.auth);

  const rows = useMemo(
    () =>
      usersList.users
        .filter(t => ~t.username?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .filter(el => {
          if (tab === 'disabled') {
            if (el.isBanned) return true;
            else return false;
          } else {
            return true;
          }
        })
        .map(item => (
          <TableRow
            isWithdrawalAccess={auth.isWithdrawalAccess}
            alignLeft={alignLeft}
            key={item.id}
            item={item}
            locale={locale}
          />
        )),
    [usersList, tab, auth, filterValue, locale],
  );

  return (
    <div className={b()}>
      <div className={b('table-container')}>
        <table className={b('table')}>
          <thead className={b()}>
            <tr className={b('main-table-head')}>
              <th className={b('table-head')}>{locale.user}</th>
              <th className={b('table-head')}>{locale.balance}</th>
              <th className={b('table-head')}>{locale.operations}</th>
              <th className={b('table-head')}>{locale.actions}</th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>
      </div>
      <div className={b('paginator-wrapper')}>
        <div className={b('extra-info')}>
          {totalCount} {locale.records}
        </div>

        <Paginator
          count={totalCount}
          currentPage={currentPage}
          onPageClick={onPageClick}
          onItemsOnPageChanged={onItemsOnPageChanged}
        />
      </div>
    </div>
  );
};

Table.propTypes = {
  usersList: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsOnPageChanged: PropTypes.func,
  onPageClick: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  alignLeft: PropTypes.bool,
};

export default Table;
