import React from 'react';
import block from 'bem-cn';
import './TableHead.scss';

const TableHead = ({ items, locale, isMobile }) => {
  const b = block('table-head');

  return (
    <thead className={b({ isMobile })}>
      <tr className={b('main-table-head')}>
        {items?.map(el => (
          <th key={el} className={b('table-head')}>
            {locale[el]}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
