import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import { daysFilterValue, daysFilterValueMobile } from '../../data';

import './DaysFilter.scss';

const b = block('days-filter');
const DaysFilter = ({ activeDay, setActiveDay, isMobile }) => {
  const locale = useSelector(state => state.locale.locale);
  const days = isMobile ? daysFilterValueMobile: daysFilterValue;

  return (
    <div className={b({ isMobile })}>
      {days.map(el => (
        <div
          onClick={() => setActiveDay(el)}
          key={el.text}
          className={b('item', { active: activeDay.text === el.text })}>
          {locale[el.text]}
        </div>
      ))}
    </div>
  );
};

export default DaysFilter;
