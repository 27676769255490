import { addNotify } from 'features/notify';
import dayjs from 'dayjs';

const actionTypes = {
  ACTION_PROCESSING: 'reports/ACTION_PROCESSING',
  GET_STATISTIC_PROVIDER: 'reports/GET_STATISTIC_PROVIDER',
  GET_PAYMENT_HISTORY: 'reports/GET_PAYMENT_HISTORY',
  GET_USER_STATISTICS: 'reports/GET_USER_STATISTICS',
  GET_SLOTS_HISTORY: 'reports/GET_SLOTS_HISTORY',
  GET_BETS_HISTORY: 'reports/GET_BETS_HISTORY',
  SET_PLAYER_USERNAME: 'reports/SET_PLAYER_USERNAME',
};

function getStatisticProvider(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DD'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DD'),
      is_direct_only: form.structure.name === 'directOnly' ? true : false,
    };

    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

      const response = await api.reports.getStatisticProvider({ id: selectedAgentId, requestedBody });

      if (response.success) {
        dispatch({
          type: actionTypes.GET_STATISTIC_PROVIDER,
          payload: response.data,
        });
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
      }

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else return;
  };
}

function getPaymentHistory(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DD'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DD'),
      username: form.username,
      role: form.role,
      is_direct_structure: form.isDirectOnly,
      is_higher_transaction_only: form.isHigher,
      is_withdrawal_transfers: form.isWithdrawal,
      is_deposit_transfers: form.isDeposit,
      is_received: form.isReceived,
      page: form.page,
      count: form.count,
    };
    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

      const response = await api.reports.getPaymentHistory({ id: selectedAgentId, requestedBody });

      if (response.success) {
        dispatch({
          type: actionTypes.GET_PAYMENT_HISTORY,
          payload: response.data,
        });
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
      }

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      return;
    }
  };
}

function getStatisticPlayer(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DD'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DD'),
      username: form.username,
      is_direct_structure: form.isDirectOnly,
      page: form.page,
      count: form.count,
    };

    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

      const response = await api.reports.getStatisticPlayer({ id: selectedAgentId, requestedBody });

      if (response.success) {
        dispatch({
          type: actionTypes.GET_USER_STATISTICS,
          payload: response.data,
        });
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
      }

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      return;
    }
  };
}

function getSlotsHistory(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DD'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DD'),
      username: form.username,
      page: form.page,
      count: form.count,
      game_type: form.gameType,
      provider: form.provider,
      status: form.status,
    };
    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

      const response = await api.reports.getSlotsHistory({ id: selectedAgentId, requestedBody });

      if (response.success) {
        dispatch({
          type: actionTypes.GET_SLOTS_HISTORY,
          payload: response.data,
        });
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
      }

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else {
      return;
    }
  };
}

function getBetsHistory(form) {
  return async (dispatch, getState, extra) => {
    const { api } = extra;
    const { selectedAgentId } = getState().tree;

    const requestedBody = {
      date_from: dayjs(form.beginDate).format('YYYY-MM-DD'),
      date_to: dayjs(form.endDate).format('YYYY-MM-DD'),
      username: form.username,
      page: form.page,
      count: form.count,
      status: form.status,
      coupon_id: form.couponId,
      is_direct_structure: form.isDirectOnly,
    };
    if (selectedAgentId) {
      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });

      const response = await api.reports.getBetsHistory({ id: selectedAgentId, requestedBody });

      if (response.success) {
        dispatch({
          type: actionTypes.GET_BETS_HISTORY,
          payload: response.data,
        });
      } else {
        dispatch(addNotify(response.errorMessage, 'error'));
      }

      dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
    } else return;
  };
}

function setPlayerUsername(username, role) {
  return async dispatch => {
    dispatch({ type: actionTypes.SET_PLAYER_USERNAME, payload: { username, role: role } });
  };
}

export {
  actionTypes,
  getStatisticProvider,
  getPaymentHistory,
  getStatisticPlayer,
  getSlotsHistory,
  getBetsHistory,
  setPlayerUsername,
};
