import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changePassword: 'Change password',
  logout: 'Logout',

  lastMovements: 'Last movements',
  agentPlatfrom: 'Agent platform',
  email: 'Email',
  password: 'Password',
  login: 'Login',
  username: 'Username',

  agents: 'Agents',
  agent: 'Agent',

  emtretenimiento: 'Somos emtretenimiento',

  changePermissions: 'Change permissions',
  permissionsDetails: 'Permissions Details',

  casino: 'Casino',
  sport: 'Sport',
  permissions: 'Permissions',
  copyPermissions: 'Copy permissions to child users',

  users: 'Users',
  seeAll: 'See all',
  seeAgents: 'See agents',
  seePlayers: 'See players',
  createAgent: 'Create agent',
  createPlayer: 'Create player',
  received: 'Received',
  reports: 'Reports',
  financialReports: 'Financial reports',
  general: 'General',
  cashDepositsWithdrawals: 'Cash (deposits and withdrawals)',
  playerReports: 'Player reports',
  playerHistory: 'Player history',
  detailByPlayer: 'Detail by player',
  sportsBets: 'Sports bets',
  finance: 'Finance',
  myDebtAccount: 'My debt account',
  accountBalance: 'Account balance',
  howToStart: 'How to start',

  search: 'Search',
  newAgent: 'New agent',
  newPlayer: 'New player',

  allUsers: 'All users',
  players: 'Players',
  disabled: 'Disabled',
  user: 'User',
  balance: 'Balance',
  operations: 'Operations',
  actions: 'Actions',
  records: 'Records',

  withdrawal: 'Withdrawal',
  deposit: 'Deposit',
  cancel: 'Cancel',
  save: 'Save',

  name: 'Name',
  surname: 'Surname',
  withdrawalAllowed: 'Withdrawal allowed',
  createSuccess: 'Success',
  amount: 'Amount',

  disable: 'Disable',
  edit: 'Edit',
  informations: 'Informations',
  id: 'ID',
  accept: 'Accept',
  disableUser: 'Disable user',
  modifyAgent: 'Modify agent',
  information: 'Information',
  hierachy: 'Hierachy',
  currency: 'Currency',
  creationDate: 'Creation date',
  modificationDate: 'Modification date',
  enable: 'Enable',
  enableUser: 'Enable user',

  today: 'Today',
  yesterday: 'Yesterday',
  lastWeek: 'Last week',
  lastMonth: 'Last month',
  dateFrom: 'Date from',
  local: 'Local',
  utc: 'UTC',
  dateUntil: 'Date until',
  help: 'Help',
  show: 'Show',
  directOnly: 'Direct Only',
  timeZoneMsg:
    'The local time zone takes into account the date and time that you have set on your device. The UTC 00:00 time zone is a universal time standard. If you select the "Local" option, the time of your country will be taken into account, and if you select the "UTC" option, the universal standard time will be taken into account.',
  10: '10',
  total_bet_count: 'Total bet count',
  total_bet: 'Total bet',
  total_won: 'Total won',
  betCount: 'Bet count',
  betAmount: 'Bet amount',
  winAmount: 'Win Amount',
  provider: 'Provider',

  searchIn: 'Search in',
  higher: 'Higher',
  transaction: 'Transaction',
  date: 'Date',
  operation: 'Operation',
  agentInitiator: 'Agent-initiator',
  initiator: 'Initiator',
  destinationAgent: 'Destination Agent',

  reportsGeneral: 'Reports general',
  playerReportsGeneral: 'Player reports general',
  sportBets: 'Sport bets',
  amountIn: 'Amount in',
  amountOut: 'Amount out',
  total: 'Total',

  gameType: 'Game Type',
  statusBet: 'Status Bet',
  win: 'Win',
  bet: 'Bet',
  refund: 'Refund',
  liveCasino: 'Live Casino',
  fiable: 'Fiable',
  all: 'All',
  slots: 'Slots',
  status: 'Status',
  balanceBefore: 'Balance before',
  balanceAfter: 'Balance after',
  userId: 'User id',
  couponId: 'Coupon id',
  wait: 'Wait',
  return: 'Return',
  loss: 'Loss',
  sold: 'Sold',
  isExpress: 'Is express',
  createdAt: 'Created at',
  totalCoeff: 'Total coeff',
  fromUser: 'From user',
  from: 'From',
  to: 'To',
  toUser: 'To user',

  subagentes: 'Subagentes',
  newPassword: 'New password',
  repeatPassword: 'Repeat password',
  passwordsDontMatch: 'Passwords do not match!',

  total_income: 'Total income',
  incomeAmount: 'Income amount',

  enterTextInLowercaseOnly: 'Enter text in lowercase only',
};
