import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import './TableRow.scss';

const TableRow = ({ items, leftAlign = false, itemsWithLeftAlign, needTable }) => {
  const b = block('adm-bets-table-row');

  return (
    <tr className={b('tr', { needTable })}>
      {items?.map((el, index) => (
        <td key={`${el}_${index}`} className={b('td', { needTable })}>
          <div className={b('td', 'data', { 'align-left': leftAlign || itemsWithLeftAlign.includes(index) })}>{el === 'Pragmatic' ? 'PragmaticLive' : el}</div>
        </td>
      ))}
    </tr>
  );
};

TableRow.propTypes = {
  items: PropTypes.array.isRequired,
  needTable: PropTypes.bool,
};

export default TableRow;
