import React, { useEffect, useMemo, useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { actions } from '../redux';
import SVG from 'components/SVG';
import TreeRow from './TreeRow/TreeRow';

import arrowSvg from './img/arrow.svg';
import userSvg from './img/user.svg';
import backSvg from './img/back.svg';

import './Tree.scss';

const b = block('tree');

const Tree = ({ callback, isMobile }) => {
  const dispatch = useDispatch();
  const usersDataList = useSelector(state => state.tree.users);
  const isFirstParentOpened = !!usersDataList.length && !!usersDataList[0].isOpened;
  const [isOpened, setOpened] = useState(false);
  const selectedAgentId = useSelector(state => state.tree.selectedAgentId);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const auth = useSelector(state => state.auth);
  const selectAgent = useCallback(
    id => {
      dispatch(actions.selectAgentId(id));
    },
    [dispatch],
  );
  const rows = useMemo(
    () => usersDataList.map(row => <TreeRow key={row.userId} nesting={[row.userId]} row={row} />),
    [usersDataList],
  );

  useEffect(() => {
    isFirstParentOpened && setOpened(isFirstParentOpened);
  }, [isFirstParentOpened]);

  useEffect(() => {
    if (!isMobile) {
      dispatch(actions.getUsersByUserId());
      selectAgent(auth.id);
    }
  }, []);

  const onClick = useCallback(() => {
    selectAgent(auth.id);
    if (usersDataList.length !== 0) {
      setOpened(prevState => !prevState);
    } else {
      setOpened(false);
    }
  }, [usersDataList.length, auth.id, selectAgent]);

  const CurrentAgent = (
    <div className={b('current-agent')} onClick={onClick}>
      {usersDataList.length !== 0 ? (
        <SVG
          svgProps={{ svg: arrowSvg }}
          className={b('current-agent', 'img-arrow', { opened: isOpened, active: selectedAgentId == auth.id })}
        />
      ) : (
        ''
      )}
      <SVG
        svgProps={{ svg: userSvg }}
        className={b('current-agent', 'img-user', { opened: isOpened, active: selectedAgentId == auth.id })}
      />
      <span className={b('current-agent', 'title', { opened: isOpened, active: selectedAgentId == auth.id })}>
        {auth.username}
      </span>
    </div>
  );

  return (
    <div className={b()}>
      <div className={b('title')} onClick={callback ? callback : () => null}>
        {callback && <SVG svgProps={{ svg: backSvg }} className={b('back-arrow')} />}
        {locale.agents}
      </div>
      {usersDataList[0]?.userId !== auth.id && CurrentAgent}
      {isOpened && <div className={b('wrapper')}>{rows}</div>}
    </div>
  );
};

export default Tree;
