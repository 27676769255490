import React, { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import './Table.scss';
import TableRow from '../TableRow/TableRow';
import TableHead from '../TableHead/TableHead';
import Paginator from 'components/Paginator';

const b = block('financial-reports-table');

const Table = ({
  currentPage,
  onPageClick,
  onItemsOnPageChanged,
  thTdItems,
  totalCount,
  needTable = false,
  isMobile,
  tdAlign,
}) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const rows = useMemo(
    () => thTdItems?.map(item => <TableRow needTable={needTable} items={Object.values(item)} tdAlign={tdAlign} />),
    [thTdItems, tdAlign],
  );

  return (
    <>
      <div className={b({ isMobile })}>
        <table className={b('table', { needTable })}>
          <TableHead
            items={thTdItems.length !== 0 ? Object.keys(thTdItems[0]) : []}
            locale={locale}
            isMobile={isMobile}
          />
          <tbody>{rows}</tbody>
        </table>
      </div>
      <div className={b('paginator-wrapper')}>
        <div className={b('extra-info')}>
          {totalCount} {locale.records}
        </div>

        <Paginator
          count={totalCount}
          currentPage={currentPage}
          onPageClick={onPageClick}
          onItemsOnPageChanged={onItemsOnPageChanged}
          color="white"
        />
      </div>
    </>
  );
};

Table.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onItemsOnPageChanged: PropTypes.func,
  onPageClick: PropTypes.func.isRequired,
  tdAlign: PropTypes.string,
  needTable: PropTypes.bool,
};

export default Table;
