import React, { useState, useEffect, useCallback, useMemo } from 'react';
import block from 'bem-cn';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router';

import { actions } from 'features/users/redux';
import { actions as treeActions } from 'features/tree';

import UsersFilterBlock from './UsersFilterBlock';

import Table from './Table/Table';
import Tabs from './Tabs/Tabs';

import './Users.scss';

const b = block('users');

const Users = () => {
  const [filterValue, onChangeFilterValue] = useState('');
  const [count, setCount] = useState(10);
  const [actionProcessing, changeActionProcessing] = useState(false);
  const [page, setPage] = useState(0);
  const { tab } = useParams();
  const auth = useSelector(state => state.auth);
  const { isNeedToRefresh } = useSelector(state => state.users);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { selectedAgentId } = useSelector(state => state.tree);

  const { email, id } = auth;

  const dispatch = useDispatch();

  const role = useMemo(() => {
    if (tab === 'players') {
      setPage(0);
      return 0;
    }
    if (tab === 'agents') {
      setPage(0);
      return 6;
    }
    if (tab === 'all' || tab === 'disabled') {
      setPage(0);
      return '';
    }
  }, [tab]);

  const isBanned = useMemo(() => {
    if (tab === 'disabled') {
      return true;
    } else return false;
  }, [tab]);

  useEffect(() => {
    setPage(0);
  }, [selectedAgentId]);

  const getUsers = data => {
    return dispatch(actions.getUsersList(data));
  };

  const selectAgent = useCallback(
    id => {
      dispatch(treeActions.selectAgentId(id));
    },
    [dispatch],
  );

  const getTreeById = name => {
    changeActionProcessing(true);
    console.log(actionProcessing);
    getUsers({ username: name ?? '' })
      .then(id => {
        if (id) {
          dispatch(treeActions.getUsersByUserId({ id, nesting: [id], name }, true)).finally(() => {
            selectAgent(id);
            changeActionProcessing(false);
          });
        }
      })
      .catch(() => changeActionProcessing(false));
  };

  useEffect(() => {
    getUsers({ username: '', role, userId: selectedAgentId || id, isBanned, count, page });
  }, [dispatch, email, role, id, isBanned, count, page, isNeedToRefresh, selectedAgentId]);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  return (
    <div className={b()}>
      <UsersFilterBlock
        callBack={() => getTreeById(filterValue ?? '')}
        actionProcessing={actionProcessing}
        filterValue={filterValue}
        changeFilterValue={changeFilterValue}
      />
      <Tabs />
      <Table
        filterValue={filterValue}
        locale={locale}
        currentPage={page}
        onPageClick={setPage}
        onItemsOnPageChanged={onItemsOnPageChanged}
        alignLeft
      />
    </div>
  );
};

export default Users;
