import dayjs from 'dayjs';

export class ReportsApiConverter {
  convertStatisticProvider = data => {
    let arrProviders = [];
    arrProviders = Object.keys(data.providers).map((el, index) => ({
      provider: el,
      betCount: data.providers[el].bet_count,
      betAmount: data.providers[el].bet_amount,
      winAmount: data.providers[el].win_amount,
      incomeAmount: data.providers[el].income_amount,
    }));

    return {
      providers: arrProviders,
      detail: data.detail,
    };
  };

  convertPaymentHistory = data => {
    let arrTransfers = [];
    arrTransfers = data.transfers.map(el => ({
      id: el?.id,
      operation: el?.operation,
      amount: el?.amount,
      fromUser: el?.from_user,
      toUser: el?.to_user,
      initiatorUser: el?.initiator_user,
      realToUser: el?.real_to_user,
      createdAt: dayjs(el?.created_at).format('DD/MM/YYYY'),
      balanceFrom: el?.balance_changes.balance_from,
      balanceTo: el?.balance_changes.balance_to,
    }));

    return {
      transfers: arrTransfers,
      details: {
        totalCount: data.details.total_count,
        totalDeposit: data.details.total_deposit,
        totalWithdraw: data.details.total_withdraw,
        deltaBalance: data.details.delta_balance,
      },
    };
  };

  convertStatisticPlayer = data => {
    let arrStatistics = [];
    arrStatistics = Object.keys(data.user_statistic).map((el, index) => ({
      id: el,
      email: data.user_statistic[el].email,
      amountIn: data.user_statistic[el].amount_in,
      amountOut: data.user_statistic[el].amount_out,
      total: data.user_statistic[el].total,
    }));

    return {
      userStatistic: arrStatistics,
      details: {
        totalCount: data.details.total_count,
      },
    };
  };

  convertSlotsHistory = data => {
    let arrData = [];
    arrData = data.data.map(el => ({
      id: el.id,
      amount: el.amount,
      // status: el.status,
      provider: el.provider === 'PRAGMATIC' ? 'PRAGMATIC LIVE' : el.provider,
      date: el.date,
      balanceBefore: el.balance_before,
      balanceAfter: el.balance_after,
      userId: el.user_id,
      gameType: el.gameType,
    }));

    return {
      data: arrData,
      totalCount: data.totalCount,
    };
  };

  convertBetsHistory = data => {
    let arrData = [];
    arrData = data.data.map(el => ({
      userId: el.user_id,
      username: el.username,
      couponId: el.coupon_id,
      amount: el.amount,
      isExpress: el.is_express,
      createdAt: dayjs(el.created_at).format('DD/MM/YYYY'),
      currency: el.currency,
      status: el.status,
      // bets: el.bets,
      totalCoeff: el.total_coeff,
      winAmount: el.win_amount,
    }));

    return {
      data: arrData,
      count: data.count,
    };
  };

  convertFinanceHistory = data => {
    let arrTransfers = [];
    arrTransfers = data.transfers.map(el => ({
      id: el?.id,
      operation: el?.operation === 0 ? 'deposit' : 'withdrawal',
      // fromUserId: el?.from_user_id,
      // toUserId: el?.to_user_id,
      fromUser: el?.from_user,
      toUser: el?.to_user,
      currency: el?.currency,
      createdAt: dayjs(el?.created_at).format('DD/MM/YYYY'),
      balanceBeforeFromUser: el?.additional_data.balance_before_from_user,
      balanceBeforeToUser: el?.additional_data.balance_before_to_user,
      balanceAfterFromUser: el?.additional_data.balance_after_from_user,
      balanceAfterToUser: el?.additional_data.balance_after_to_user,
    }));

    return {
      transfers: arrTransfers,
      details: {
        totalCount: data.details.total_count,
      },
    };
  };
}
