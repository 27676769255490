import EnPNG from "./img/en.png";
import EsPNG from "./img/es.png";

export const languages = {
  ES: "es-es",
  EN: "en-US"
};

export const languagesWithIcons = {
  "es-es": { lang: languages.ES, icon: EsPNG, text: "Español" },
  "en-US": { lang: languages.EN, icon: EnPNG, text: "English" }
};

export const shortLanguages = {
  "es-es": "es",
  "en-US": "en"
};
