import React from 'react';
import block from 'bem-cn';
import './TableHead.scss';

const TableHead = ({ locale, isMobile }) => {
  const b = block('table-head-cash');

  return (
    <thead className={b({ isMobile })}>
      <tr className={b('main-table-head')}>
        <th className={b('table-head')}>{locale.transaction}</th>
        <th className={b('table-head')}>{locale.date}</th>
        <th className={b('table-head', { 'align-left': true })}>{locale.operation}</th>
        <th className={b('table-head', { 'align-left': true })}>{locale.initiator}</th>
        <th className={b('table-head', { 'align-left': true })}>{locale.from}</th>
        <th className={b('table-head', { 'align-left': true })}>{locale.to}</th>
        <th className={b('table-head', { 'align-left': true })}>{locale.amount}</th>
        <th className={b('table-head')}>{locale.balance}</th>
      </tr>
    </thead>
  );
};

export default TableHead;
