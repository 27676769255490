import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button';
import SVG from 'components/SVG';
import Switcher from 'components/Switcher';
import Spinner from 'components/Spinner/view/desktop';

import { actions } from 'features/users/redux';

import { permissionsDetailsData } from '../../../../data';

import usersSVG from 'features/users/img/users.svg';
import detailsSVG from 'features/users/img/details.svg';
import dropdownSVG from 'features/users/img/dropdown.svg';

import './ChangePermissions.scss';

const b = block('change-permissions-modal-desktop');

const ChangePermissions = ({ closeModal, locale, user }) => {
  const dispatch = useDispatch();
  const {
    userInfo: { bannedProviders, lockData },
    actionProcessing,
  } = useSelector(state => state.users);

  const [categoryOpen, setCategoryOpen] = useState(
    permissionsDetailsData.reduce((acc, curr) => ((acc[curr.category] = false), acc), {}),
  );
  const [permissionsForAgent, setPermissionsForAgent] = useState({
    isOpen: false,
    category: '',
    enabled: true,
    isChildItem: false,
    permissionsToChildUsers: true,
    items: [],
  });

  useEffect(() => {
    setPermissionsForAgent(prevState => ({
      ...prevState,
      permissionsToChildUsers: lockData.includes(permissionsForAgent.category),
    }));
  }, [lockData, permissionsForAgent.category]);

  useEffect(() => {
    dispatch(actions.getBannedProviders({ id: user.id }));
  }, [dispatch, user]);

  const handleSetProviderState = data => dispatch(actions.setBannedProviders({ data }));
  const handleSavePermissionsForAgent = () => {
    const data = {
      providers: permissionsForAgent.isChildItem
        ? permissionsForAgent.items
        : permissionsDetailsData?.filter(item => item.category === permissionsForAgent.category)[0].items,
      action: permissionsForAgent?.enabled ? 'remove' : 'add',
      isFullBranch: permissionsForAgent.permissionsToChildUsers,
      id: user.id,
      category: permissionsForAgent.category,
    };

    dispatch(actions.setBannedProviders({ data }));
  };

  const checkIsEqual = array => {
    const filteredArray = bannedProviders?.filter(item => array.includes(item));

    return array.length === filteredArray?.length;
  };

  const findCategory = item =>
    permissionsDetailsData?.filter(categoryItem => categoryItem.items.includes(item))[0].category;

  const permissionsDetailsItems = permissionsDetailsData.map(item => (
    <div className={b('permissions', 'container')} key={item.category}>
      <div className={b('permissions', 'item')}>
        <div className={b('permissions', 'item-container')}>
          <SVG
            onClick={() => setCategoryOpen({ ...categoryOpen, [item.category]: !categoryOpen[item.category] })}
            containerClassName={b('permissions', 'item-dropdown')}
            svgProps={{ svg: dropdownSVG }}
          />
          <div className={b('permissions', 'item-title')}>{locale[item.category]}</div>
        </div>
        <div className={b('permissions', 'item-container')}>
          <div className={b('permissions', 'item-switcher')}>
            <Switcher
              isActive={!checkIsEqual(item.items)}
              onSwitch={() =>
                handleSetProviderState({
                  providers: item.items,
                  action: checkIsEqual(item.items) ? 'remove' : 'add',
                  isFullBranch: true,
                  id: user.id,
                  category: item.category,
                })
              }
            />
          </div>
          {user.role !== '0' && (
            <SVG
              onClick={() =>
                setPermissionsForAgent({
                  ...permissionsForAgent,
                  isOpen: true,
                  category: item.category,
                  enabled: !checkIsEqual(item.items),
                  items: item.items,
                  isChildItem: false,
                })
              }
              containerClassName={b('permissions', 'item-details')}
              svgProps={{ svg: detailsSVG }}
            />
          )}
        </div>
      </div>
      {categoryOpen[item.category] &&
        item.items.map(item => (
          <div key={item} className={b('permissions', 'item', { inner: true })}>
            <div className={b('permissions', 'item-container')}>
              <SVG
                containerClassName={b('permissions', 'item-dropdown', { inner: true })}
                svgProps={{ svg: dropdownSVG }}
              />
              <div className={b('permissions', 'item-title', { inner: true })}>{item}</div>
            </div>
            <div className={b('permissions', 'item-container')}>
              <div className={b('permissions', 'item-switcher')}>
                <Switcher
                  isActive={!bannedProviders.includes(item)}
                  onSwitch={() =>
                    handleSetProviderState({
                      providers: [item],
                      action: bannedProviders.includes(item) ? 'remove' : 'add',
                      isFullBranch: true,
                      id: user.id,
                      category: findCategory(item),
                    })
                  }
                />
              </div>
              {user.role !== '0' && (
                <SVG
                  onClick={() =>
                    setPermissionsForAgent({
                      ...permissionsForAgent,
                      isOpen: true,
                      category: item,
                      enabled: !bannedProviders.includes(item),
                      items: [item],
                      isChildItem: true,
                    })
                  }
                  containerClassName={b('permissions', 'item-details')}
                  svgProps={{ svg: detailsSVG }}
                />
              )}
            </div>
          </div>
        ))}
    </div>
  ));

  const permissionsDetailsForAgent = (
    <div>
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>{locale.permissions} </div>
          <div className={b('header-info', 'block-username', { capitalized: true })}>
            {permissionsForAgent.category}
          </div>
        </div>
      </div>
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title', { withGap: true })}>{locale.enable} </div>
          <div className={b('permissions', 'item-switcher')}>
            <Switcher
              isActive={permissionsForAgent.enabled}
              onSwitch={() =>
                setPermissionsForAgent({
                  ...permissionsForAgent,
                  enabled: !permissionsForAgent.enabled,
                })
              }
            />
          </div>
        </div>
      </div>
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title', { fullWidth: true })}>
            {locale.copyPermissions}
            <div className={b('permissions', 'item-switcher', { noGap: true })}>
              <Switcher
                isActive={permissionsForAgent.permissionsToChildUsers}
                onSwitch={() =>
                  setPermissionsForAgent({
                    ...permissionsForAgent,
                    permissionsToChildUsers: !permissionsForAgent.permissionsToChildUsers,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('header-info')}>
        <div className={b('header-info', 'block')}>
          <div className={b('header-info', 'block-title')}>
            <SVG containerClassName={b('header-info', 'icon')} svgProps={{ svg: usersSVG }} />
          </div>
          <div className={b('header-info', 'block-username')}>{user.username}</div>
        </div>
      </div>
      <div className={b('permissions')}>
        {permissionsForAgent.isOpen ? permissionsDetailsForAgent : permissionsDetailsItems}
      </div>
      <div className={b('bottom')}>
        <div className={b('buttons')}>
          <Button callBack={closeModal} text={locale.cancel} size="high" color="modalWhite" />
          {permissionsForAgent.isOpen && (
            <Button callBack={handleSavePermissionsForAgent} text={locale.save} size="high" color="modalBlue" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePermissions;
