import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Select from 'components/Select/desktop';
import Button from 'components/Button';
import DateInput from 'components/DateInput';
import Input from 'components/Input';
import CheckBox from 'components/CheckBox';

import DaysFilter from 'features/reports/view/DaysFilter';

import { searchInItems } from 'features/reports/data';

import { actions } from 'features/reports/redux';

import './FilterBlock.scss';

const b = block('filter-block');

const FilterBlock = ({ count, currentPage, isMobile, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'day')),
    endDate: new Date(dayjs()),
  });
  const username = useSelector(state => state.reports.agentName);

  const [activeDay, setActiveDay] = useState('');
  const [filters, setFilters] = useState({
    role: searchInItems[0],
    user: username,
  });
  const [isDirectOnly, setIsDirectOnly] = useState(false);
  const [isHigher, setIsHigher] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdrawal, setIsWithdrawal] = useState(false);
  const [isReceived, setIsReceived] = useState(false);

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }

    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  const handleSearchInChange = value => {
    const searchInItem = searchInItems.find(item => item.value === value);
    setFilters({ ...filters, role: searchInItem });
  };

  const handleUserChange = useCallback(
    e => {
      setFilters({ ...filters, [e.currentTarget.name]: e.currentTarget.value });
    },
    [filters],
  );

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  useEffect(() => {
    return () => {
      dispatch(actions.setPlayerUsername('', searchInItems[0].value));
    };
  }, []);

  const onClickSearch = () => {
    handleSearch();
    onPageClick(0);
  };

  const handleSearch = useCallback(() => {
    const requestedData = {
      isDirectOnly,
      isHigher,
      isDeposit,
      isWithdrawal,
      isReceived,
      role: filters.role.value,
      username: filters.user,
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getPaymentHistory(requestedData));
  }, [
    count,
    currentPage,
    date,
    dispatch,
    filters.role.value,
    filters.user,
    isDeposit,
    isDirectOnly,
    isHigher,
    isWithdrawal,
    isReceived,
  ]);

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b({ isMobile })}>
      <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} isMobile={isMobile} />

      <div className={b('block')}>
        <div className={b('filter-row')}>
          <DateInput
            name="dateFrom"
            text="dateFrom"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.beginDate}
            onChange={onChangeBeginDate}
          />
        </div>
        <div className={b('filter-row')}>
          <DateInput
            name="dateUntil"
            text="dateUntil"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.endDate}
            onChange={onChangeEndDate}
          />
        </div>
        <div className={b('filter-row')}>
          <p className={b('filter-row', 'title')}>{locale.searchIn}</p>
          <Select name="role" items={searchInItems} activeItem={filters.role} onChange={handleSearchInChange} />
        </div>
        <div className={b('filter-row')}>
          <p className={b('filter-row', 'title')}>
            {locale.user}
            <span className={b('advice')}>{` (${locale.enterTextInLowercaseOnly})`}</span>
          </p>
          <Input
            size="low"
            type="lowWhite"
            value={filters.user}
            callBack={handleUserChange}
            placeholder={locale.username}
            name="user"
          />
        </div>
      </div>
      <div className={b('block', { type: isMobile ? 'grid' : '' })}>
        <div className={b('filter-row-checkbox')}>
          <CheckBox checked={isDirectOnly} onChange={() => setIsDirectOnly(!isDirectOnly)} name="isDirectOnly" />
          <p className={b('filter-row-checkbox', 'title')}>{locale.directOnly}</p>
        </div>
        <div className={b('filter-row-checkbox')}>
          <CheckBox checked={isHigher} onChange={() => setIsHigher(!isHigher)} name="isHigher" />
          <p className={b('filter-row-checkbox', 'title')}>{locale.higher}</p>
        </div>
        <div className={b('filter-row-checkbox')}>
          <CheckBox checked={isDeposit} onChange={() => setIsDeposit(!isDeposit)} name="isDeposit" />
          <p className={b('filter-row-checkbox', 'title')}>{locale.deposit}</p>
        </div>
        <div className={b('filter-row-checkbox')}>
          <CheckBox checked={isWithdrawal} onChange={() => setIsWithdrawal(!isWithdrawal)} name="isWithdrawal" />
          <p className={b('filter-row-checkbox', 'title')}>{locale.withdrawal}</p>
        </div>
        <div className={b('filter-row-checkbox')}>
          <CheckBox checked={isReceived} onChange={() => setIsReceived(!isReceived)} name="isReceived" />
          <p className={b('filter-row-checkbox', 'title')}>{locale.received}</p>
        </div>
        <div className={b('search-btn')}>
          <Button callBack={() => onClickSearch()} text={locale.search} search />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
