import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import DateInput from 'components/DateInput';
import DaysFilter from 'features/reports/view/DaysFilter';

import { actions } from 'features/finance';

import './FilterBlock.scss';

const b = block('filter-block-player-history-general');

const FilterBlock = ({ count, currentPage, isMobile, onPageClick }) => {
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();
  const [date, setDate] = useState({
    beginDate: new Date(dayjs().add(-1, 'day')),
    endDate: new Date(dayjs()),
  });
  const [activeDay, setActiveDay] = useState('');

  const onChangeBeginDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, beginDate: value });
    }
    setActiveDay('');
  };

  const onChangeEndDate = value => {
    if (!isNaN(value.valueOf())) {
      setDate({ ...date, endDate: value });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [count, currentPage]);

  const onClickSearch = () => {
    onPageClick(0);
    handleSearch();
  };

  const handleSearch = () => {
    const requestedData = {
      count,
      page: currentPage,
      ...date,
    };
    dispatch(actions.getFinanceHistory(requestedData));
  };

  const onChangeActiveDay = useCallback(dayFilter => {
    setActiveDay(dayFilter);
    setDate({ ...date, beginDate: new Date(dayjs().add(dayFilter.value, 'day')) });
  }, []);

  return (
    <div className={b({ isMobile })}>
      <DaysFilter activeDay={activeDay} setActiveDay={onChangeActiveDay} isMobile={isMobile} />

      <div className={b('block')}>
        <div className={b('filter-row')}>
          <DateInput
            name="dateFrom"
            text="dateFrom"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.beginDate}
            onChange={onChangeBeginDate}
          />
        </div>
        <div className={b('filter-row')}>
          <DateInput
            name="dateUntil"
            text="dateUntil"
            size="middle"
            rounded
            format="dd/MM/yyyy"
            value={date.endDate}
            onChange={onChangeEndDate}
          />
        </div>

        <div className={b('search-btn')}>
          <Button callBack={() => onClickSearch()} text={locale.search} search />
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
