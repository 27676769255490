import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import './TableRow.scss';

const TableRow = ({ items, needTable = false }) => {
  const b = block('adm-bets-table-row');

  return (
    <tr className={b('tr', { needTable })}>
      {items?.map((el, index) => (
        <td key={`${el}_${index}`} className={b('td', { needTable })}>
          {el}
        </td>
      ))}
    </tr>
  );
};

TableRow.propTypes = {
  items: PropTypes.array.isRequired,
  needTable: PropTypes.bool,
};

export default TableRow;
