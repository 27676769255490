import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import block from 'bem-cn';

import './TableProviders.scss';
import TableRow from './TableRow/TableRow';
import TableHead from './TableHead/TableHead';

const b = block('reports-table-providers');

const TableProviders = ({ thItems, trItems, isMobile, leftAlign = false, needTable = false, itemsWithLeftAlign }) => {
  const { tab } = useParams();
  const locale = useSelector(state => state.locale.locale);

  const rows = useMemo(
    () =>
      trItems?.map(item => (
        <TableRow
          items={Object.values(item)}
          needTable={needTable}
          leftAlign={leftAlign}
          itemsWithLeftAlign={itemsWithLeftAlign}
        />
      )),
    [trItems, leftAlign, itemsWithLeftAlign, needTable],
  );

  return (
    <div className={b({ isMobile })}>
      <table className={b('table')}>
        <TableHead items={Object.keys(thItems[0])} locale={locale} isMobile={isMobile} />
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

TableProviders.propTypes = {};

export default TableProviders;
