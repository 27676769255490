import React, { useState, useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'components/Input';
import Button from 'components/Button';
import { actions } from '../../redux';
import logoPNG from 'shared/img/logo.png';
import { Locale } from 'features/locale';

import './Auth.scss';

const Auth = () => {
  const b = block('auth');
  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');
  const [disable, setDisable] = useState(true);

  const locale = useSelector(state => state.locale.locale);
  const { isProcess } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const signIn = useCallback(() => {
    if (!isProcess) {
      dispatch(actions.login(userName, password));
    }
  }, [userName, password, isProcess, dispatch]);

  useEffect(() => {
    if (password.length !== 0 && userName.length !== 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [password, userName]);

  return (
    <section className={b()}>
      <div className={b('language-selector')}>
        <Locale />
      </div>

      <div className={b('content')}>
        <div className={b('title')}>
          <img src={logoPNG} className={b('title', 'logo')} alt="logo" />
          <span className={b('title-logo')}>{locale.emtretenimiento}</span>
          <span className={b('title', 'text')}>{locale.agentPlatfrom}</span>
        </div>
        <div className={b('form')}>
          <div className={b('input-block')}>
            <div className={b('input-block', 'title')}>{`${locale.username}:`}</div>
            <Input
              value={userName}
              onChange={e => changeUserName(e.currentTarget.value)}
              placeholder={locale.username}
              size="high"
            />
          </div>
          <div className={b('input-block')}>
            <div className={b('input-block', 'title')}>{`${locale.password}:`}</div>
            <Input
              value={password}
              onChange={e => passwordUserName(e.currentTarget.value)}
              type="password"
              placeholder="●●●●●●●●●●●●"
              size="high"
            />
          </div>
          <div className={b('bottom')}>
            <Button text={locale.login} callBack={signIn} disabled={disable} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Auth;
