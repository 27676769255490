import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions } from 'features/users/redux';
import { actions as reportsActions } from 'features/reports/redux';

import { ModalContext } from 'components/ModalProvider/ModalProvider';
import SVG from 'components/SVG';
import Dropdown from 'components/Dropdown';
import Deposit from '../Modals/Deposit/Deposit';
import Withdrawal from '../Modals/Withdrawal/Withdrawal';

import depositSVG from '../../../img/deposit.svg';
import userSVG from '../../../img/user.svg';
import agentSVG from '../../../img/agent.svg';
import withdrawalSVG from '../../../img/withdrawal.svg';

import ChangePassword from '../Modals/ChangePassword/ChangePassword';
import DisableUser from '../Modals/DisableUser/DisableUser';
import ModifyAgent from '../Modals/ModifyAgent/ModifyAgent';
import Information from '../Modals/Information/Information';
import ChangePermissions from '../Modals/ChangePermissions/ChangePermissions';

import { getActionItems } from '../../../data';

import './TableRow.scss';

const TableRow = ({ item, isWithdrawalAccess, alignLeft, locale }) => {
  const b = block('adm-bets-table-row');
  const dispatch = useDispatch();
  const history = useHistory();

  const { openModal, closeModal } = useContext(ModalContext);

  const handleClickDeposit = () => {
    openModal({
      title: locale.deposit,
      content: <Deposit closeModal={closeModal} locale={locale} user={item} />,
    });
  };

  const handleClickWithdrawal = () => {
    openModal({
      title: locale.withdrawal,
      content: <Withdrawal closeModal={closeModal} locale={locale} user={item} />,
    });
  };

  const selectItemChangedHandler = itemTitle => {
    if (itemTitle === 'changePassword') {
      openModal({
        title: locale.changePassword,
        content: <ChangePassword closeModal={closeModal} locale={locale} user={item} />,
      });
    }
    if (itemTitle === 'disable') {
      openModal({
        title: locale.disableUser,
        content: <DisableUser closeModal={closeModal} locale={locale} user={item} isDisable={true} />,
      });
    }
    if (itemTitle === 'enable') {
      openModal({
        title: locale.enableUser,
        content: <DisableUser closeModal={closeModal} locale={locale} user={item} isDisable={false} />,
      });
    }
    if (itemTitle === 'changePermissions') {
      openModal({
        title: locale.permissionsDetails,
        content: <ChangePermissions closeModal={closeModal} locale={locale} user={item} isDisable={true} />,
      });
    }
    if (itemTitle === 'edit') {
      dispatch(actions.getUserInfo({ id: item.id }));
      openModal({
        title: locale.modifyAgent,
        content: <ModifyAgent closeModal={closeModal} locale={locale} user={item} />,
      });
    }
    if (itemTitle === 'informations') {
      dispatch(actions.getUserInfo({ id: item.id }));
      openModal({
        title: locale.informations,
        content: <Information closeModal={closeModal} locale={locale} />,
      });
    }
    if (itemTitle === 'lastMovements') {
      dispatch(reportsActions.setPlayerUsername(item.username, item.role));
      history.replace(item.role === '6' ? '/reports/financial-reports/cash' : '/reports/player-reports/player-history');
    }
  };

  return (
    <tr className={b('tr')}>
      <td className={b('td')}>
        <div className={b('td', { alignLeft: alignLeft }, 'data')}>
          <SVG svgProps={{ svg: item.role === '6' ? agentSVG : userSVG }} className={b('td', 'username-img')} />
          {item.username}
        </div>
      </td>
      <td className={b('td')}>{item.balance}</td>
      <td className={b('td')}>
        <div className={b('td', 'data')}>
          <SVG onClick={handleClickDeposit} svgProps={{ svg: depositSVG }} className={b('td', 'deposit-img')} />
          {isWithdrawalAccess && (
            <SVG
              onClick={handleClickWithdrawal}
              svgProps={{ svg: withdrawalSVG }}
              className={b('td', 'withdrawal-img')}
            />
          )}
        </div>
      </td>
      <td className={b('td')}>
        {+item.role !== 3 && (
          <div className={b('td', 'data', 'actions')}>
            <Dropdown
              items={getActionItems({ role: item.role, isBanned: item.isBanned })}
              onChange={selectItemChangedHandler}
              locale={locale}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
  alignLeft: PropTypes.bool,
};

export default TableRow;
