import React from "react";
import block from "bem-cn";
import { useSelector } from 'react-redux';

import ListItem from "./ListItem";
import logoPNG from "shared/img/logo.png";

import "./Sidebar.scss";

import { sidebarMenu } from "../../data";

const b = block("sidebar");

const Sidebar = ({ changeMenuOpen = () => null, isMobile = false }) => {
  const locale = useSelector(state => state.locale.locale);
  return (
    <div className={b({ isMobile })}>
      <div className={b("logo-wrapper")}>
        <img src={logoPNG} className={b("logo", { isMobile })} alt="logo" />
        <span className={b('title-logo')}>{locale.emtretenimiento}</span>
      </div>
      <div className={b("menu")}>
        {sidebarMenu.map(el => (
          <ListItem item={el} key={el.title} changeMenuOpen={changeMenuOpen} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
