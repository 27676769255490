import React, { useEffect } from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SVG from 'components/SVG';
import { actions } from 'features/finance';

import arrowSVG from 'features/finance/img/arrow.svg';

import './HowToStart.scss';

const b = block('how-to-start');

const HowToStart = ({ isMobile }) => {
  const flatPages = useSelector(state => state.finance.flatPages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getFlatPages());
  }, []);

  return (
    <div className={b({ isMobile })}>
      {flatPages.map(el => (
        <Link to={`/finance/how-to-start/${el.idName}`} className={b('link')}>
          <div className={b('link', 'title')}>{el.name}</div>
          <SVG svgProps={{ svg: arrowSVG }} className={b('link', 'arrow')} />
        </Link>
      ))}
    </div>
  );
};

export default HowToStart;
