import accountBalance from './img/account-balance.svg';
import eye from './img/eye.svg';
import finance from './img/finance.svg';
import grayReports from './img/gray-reports.svg';
import howToStart from './img/how-to-start.svg';
import myDebtAccount from './img/my-debt-account.svg';
import reports from './img/reports.svg';
import time from './img/time.svg';
import users from './img/users.svg';
import person from './img/person.svg';

export const sidebarMenu = [
  {
    title: 'users',
    img: users,
    route: '/users/all',
    routeForActiveTab: '',
    level: 0,

    children: [
      {
        title: 'seeAll',
        route: '/users/all',
        routeForActiveTab: '/users/all',
        img: eye,
        level: 1,
      },
      {
        title: 'seeAgents',
        route: '/users/agents',
        routeForActiveTab: '/users/agents',
        img: eye,
        level: 1,
      },
      {
        title: 'seePlayers',
        route: '/users/players',
        routeForActiveTab: '/users/players',
        img: eye,
        level: 1,
      },
      {
        title: 'createAgent',
        route: '/users/all',
        routeForActiveTab: '',
        modal: true,
        img: eye,
        level: 1,
      },
      {
        title: 'createPlayer',
        route: '/users/all',
        routeForActiveTab: '',
        img: eye,
        modal: true,
        level: 1,
      },
    ],
  },
  {
    title: 'reports',
    route: '/reports/financial-reports/general',
    routeForActiveTab: '',
    img: reports,
    level: 0,
    children: [
      {
        title: 'financialReports',
        img: grayReports,
        route: '/reports/financial-reports/general',
        routeForActiveTab: '',
        level: 1,
        children: [
          {
            title: 'general',
            img: grayReports,
            route: '/reports/financial-reports/general',
            routeForActiveTab: '/reports/financial-reports/general',
            level: 2,
          },
          {
            title: 'cashDepositsWithdrawals',
            img: grayReports,
            route: '/reports/financial-reports/cash',
            routeForActiveTab: '/reports/financial-reports/cash',
            level: 2,
          },
        ],
      },
      {
        title: 'playerReports',
        route: '/reports/player-reports/player-history',
        img: grayReports,
        routeForActiveTab: '',
        level: 1,
        children: [
          {
            title: 'playerHistory',
            img: time,
            route: '/reports/player-reports/player-history',
            routeForActiveTab: '/reports/player-reports/player-history',
            level: 2,
          },
          {
            title: 'detailByPlayer',
            img: time,
            route: '/reports/player-reports/detail-by-player',
            routeForActiveTab: '/reports/player-reports/detail-by-player',
            level: 2,
          },
          {
            title: 'sportsBets',
            img: grayReports,
            route: '/reports/player-reports/sport-bets',
            routeForActiveTab: '/reports/player-reports/sport-bets',
            level: 2,
          },
        ],
      },
    ],
  },
  {
    title: 'finance',
    route: '/finance/my-debt-account',
    routeForActiveTab: '',
    img: finance,
    level: 0,
    children: [
      {
        title: 'myDebtAccount',
        img: myDebtAccount,
        route: '/finance/my-debt-account',
        routeForActiveTab: '/finance/my-debt-account',
        level: 1,
      },
      // {
      //   title: 'accountBalance',
      //   img: accountBalance,
      //   route: '/finance/account-balance',
      //   level: 1,
      // },
      {
        title: 'howToStart',
        img: howToStart,
        route: '/finance/how-to-start',
        routeForActiveTab: '/finance/how-to-start',
        level: 1,
      },
    ],
  },
];

export const agentsItem = {
  title: 'agents',
  routeForActiveTab: '',
  img: person,
  level: 0,
};
