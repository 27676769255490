import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

import SVG from 'components/SVG';

import arrowSVG from './img/arrow-right.svg';

import './TableRow.scss';

const TableRow = ({ item, locale }) => {
  const b = block('adm-bets-table-row');

  return (
    <tr className={b('tr')}>
      <td className={b('td')}>
        <div className={b('td', 'data')}>{item.id}</div>
      </td>
      <td className={b('td')}>
        <div className={b('td', 'data')}>{item.createdAt}</div>
      </td>
      <td className={b('td', { 'align-left': true })}>
        <div className={b('td', 'data')}>{item.operation === 0 ? locale.deposit : locale.withdrawal}</div>
      </td>
      <td className={b('td', { 'align-left': true })}>
        <div className={b('td', 'data')}> {item.initiatorUser}</div>
      </td>
      <td className={b('td', { 'align-left': true })}>
        <div className={b('td', 'data')}>{item.fromUser}</div>
      </td>
      <td className={b('td', { 'align-left': true })}>
        <div className={b('td', 'data')}>{item.toUser}</div>
      </td>

      <td className={b('td', { 'align-left': true })}>
        <div className={b('td', 'data')}>{item.amount}</div>
      </td>
      <td className={b('td')}>
        <div className={b('td', 'data')}>
          {item.balanceFrom}
          <SVG svgProps={{ svg: arrowSVG }} className={b('arrow')} />
          {item.balanceTo}
        </div>
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TableRow;
