import { getLastElOfArr } from 'services/helper';
export class TreeApiConverter {
  convertDescendants = (parentId, userId, subusersCount = 0, level = 1, username) => ({
    parentId,
    subusersCount,
    userId,
    level,
    username,
  });

  convertUsersById = ({ data, id, isClickedByButton, targetName }) => {
    const { path, descendants } = data;
    const parents = path.map(el => ({
      ...this.convertDescendants(el.parent_agent_id, el.id, 1, 1, el.username),
      isOpened: true,
    }));

    const parsedDescendants = descendants.map(t =>
      this.convertDescendants(id, t.id, t.child_count, t.level, t.username),
    );

    const buildParentTree = arr => {
      const isLast = arr[0]?.userId === getLastElOfArr(parents)?.userId;
      const targetUser = this.convertDescendants(getLastElOfArr(arr)?.userId, id, descendants.length, 1, targetName);
      if (arr[0].userId === targetUser.userId)
        return [{ ...targetUser, childrens: [...parsedDescendants], isOpened: true }];
      return [
        {
          ...arr[0],
          childrens: isLast
            ? [
                descendants.length
                  ? { ...targetUser, childrens: [...parsedDescendants], isOpened: true }
                  : { ...targetUser },
              ]
            : buildParentTree(arr.slice(1)),
        },
      ];
    };

    if (isClickedByButton) return buildParentTree(parents);
    return parsedDescendants;
  };
}
