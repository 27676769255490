export const daysFilterValue = [
  {
    text: 'today',
    value: 0,
  },
  {
    text: 'yesterday',
    value: -1,
  },
  {
    text: 'lastWeek',
    value: -7,
  },
  {
    text: 'lastMonth',
    value: -31,
  },
];

export const daysFilterValueMobile = [
  {
    text: 'today',
    value: 0,
  },
  {
    text: 'yesterday',
    value: -1,
  },
  {
    text: 'lastWeek',
    value: -7,
  },
];

export const structure = [
  {
    name: 'allUsers',
    value: 'allUsers',
  },
  {
    name: 'directOnly',
    value: 'directOnly',
  },
];

export const searchInItems = [
  {
    name: 'agent',
    value: '6',
  },
  {
    name: 'user',
    value: '0',
  },
];

export const providerItems = [
  {
    name: 'all',
    value: '',
  },
  {
    name: 'PRAGMATIC SLOTS',
    value: 'PRAGMATIC_SLOTS',
  },
  {
    name: 'PRAGMATIC LIVE',
    value: 'PRAGMATIC',
  },
  {
    name: 'EZUGI',
    value: 'EZUGI',
  },
  {
    name: 'POPOKGAMING',
    value: 'POPOKGAMING',
  },
  {
    name: 'OUTCOMEBET',
    value: 'OUTCOMEBET',
  },
  {
    name: 'EVOPLAY',
    value: 'EVOPLAY',
  },
  {
    name: 'EVOLUTION',
    value: 'EVOLUTION',
  },
  {
    name: 'TVBET',
    value: 'TVBET',
  },
  {
    name: 'FIABLE',
    value: 'FIABLE',
  },
  {
    name: 'BSWGAMES',
    value: 'BSWGAMES',
  },
  {
    name: 'INBET',
    value: 'INBET',
  },
  {
    name: 'SOFTGAMING',
    value: 'SOFTGAMING',
  },
  {
    name: 'SLOTEGRATOR',
    value: 'SLOTEGRATOR',
  },
  {
    name: 'BETRADAR',
    value: 'BETRADAR',
  },
  {
    name: 'LINE1X',
    value: 'LINE1X',
  },
  {
    name: 'FLG',
    value: 'FLG',
  },
];

export const statusBets = [
  {
    name: 'win',
    value: 1,
  },
  {
    name: 'bet',
    value: 2,
  },
  {
    name: 'refund',
    value: 3,
  },
];

export const statusSportBets = [
  {
    name: 'win',
    value: 1,
  },
  {
    name: 'bet',
    value: 2,
  },
  {
    name: 'refund',
    value: 3,
  },
];

export const gameTypes = [
  {
    name: 'slots',
    value: 'slots',
  },
  {
    name: 'liveCasino',
    value: 'live-casino',
  },
  {
    name: 'fiable',
    value: 'fiable',
  },
  {
    name: 'all',
    value: 'all',
  },
];
