import React, { useCallback, useContext, useEffect } from 'react';
import block from 'bem-cn';
import { useHistory } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';

import './HeaderDropdown.scss';

import threeDotesSVG from '../../img/three-dotes.svg';
import arrowSVG from '../../img/blue-arrow.svg';
import userSVG from '../../img/user.svg';

import { ModalContext } from 'components/ModalProvider/ModalProvider';
import ChangePassword from 'components/Header/Modals/ChangePassword/ChangePassword';

import { actions as authActions } from 'features/Auth';

const b = block('header-dropdown');
const HeaderDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale);

  const { openModal, closeModal } = useContext(ModalContext);

  const onClick = useCallback(() => {
    openModal({
      title: locale.changePassword,
      content: <ChangePassword closeModal={closeModal} locale={locale} id={auth.id} />,
    });
  }, [closeModal, locale, openModal, auth]);

  useEffect(() => {
    const value = setInterval(() => dispatch(authActions.getBalance()), 3000);
    return () => {
      clearInterval(value);
    };
  }, []);

  const logout = () => {
    dispatch(authActions.logOut());
  };

  return (
    <div className={b()}>
      <div className={b('upper')}>
        <div className={b('user')}>
          <SVGInline svg={userSVG} className={b('user', 'img').toString()} />
          <div className={b('user', 'username')}>{`${auth.username} (${auth.id})`}</div>
        </div>
        <div className={b('upper', 'balance')}>
          {locale.balance}: {auth.balance}
        </div>
      </div>
      <div className={b('bottom')}>
        <div onClick={onClick} className={b('bottom', 'change-password-wrapper')}>
          <div className={b('bottom', 'change-password')}>
            <SVGInline svg={threeDotesSVG} className={b('bottom', 'change-password_img').toString()} />
            <div className={b('bottom', 'change-password_title')}>{locale.changePassword}</div>
          </div>
          <SVGInline svg={arrowSVG} className={b('bottom', 'arrow-img').toString()} />
        </div>
        <div onClick={() => logout()} className={b('bottom', 'logout-btn')}>
          {locale.logout}
        </div>
      </div>
    </div>
  );
};

export default HeaderDropdown;
