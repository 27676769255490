import React from 'react';
import block from 'bem-cn';
import { Link, useLocation } from 'react-router-dom';

import { tabLinks } from './data';

import './Tabs.scss';

const b = block('tabs');

const Tabs = () => {
  const location = useLocation();
  return (
    <div className={b()}>
      {tabLinks.map((el, index) => (
        <Link to={el.link} key={el.name} className={b('item', { active: location.pathname.includes(el.link) })}>
          {el.name}
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
