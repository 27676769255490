import React, { useState, useMemo } from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';

import actionsSVG from './img/actions.svg';

import './Dropdown.scss';

const b = block('dropdown');

const Dropdown = ({ items, activeItem, onChange, locale, isMobile = false }) => {
  console.log('items: ', items);
  const [isOpen, changeOpen] = useState(false);

  const itemsList = useMemo(
    () =>
      items?.map(item => (
        <li key={item.name} className={b('item')} onClick={() => onChange(item.title)}>
          <SVG className={b('item-icon').toString()} svgProps={{ svg: item.svg }} />
          <span className={b('item-value')}>{locale[item.title]}</span>
        </li>
      )),
    [items, onChange, locale],
  );

  return (
    <div className={b({ open: isOpen })} onClick={() => changeOpen(!isOpen)}>
      <div className={b('action')}>
        <SVG className={b('action-icon').toString()} svgProps={{ svg: actionsSVG }} />
      </div>
      {isOpen && (
        <ul className={b('items', { isMobile })} onMouseLeave={() => changeOpen(false)}>
          {itemsList}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
