import React, { useEffect } from 'react';
import block from 'bem-cn';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { actions as authAction } from 'features/Auth';
import Notify from 'features/notify/view';
import { ModalProvider } from 'components/ModalProvider/ModalProvider';
import Users from 'features/users/desktop';

import General from 'features/reports/view/Pages/FinancialReports/General';
import Cash from 'features/reports/view/Pages/FinancialReports/Cash';
import DetailByPlayer from 'features/reports/view/Pages/PlayerReports/DetailByPlayer';
import PlayerHistory from 'features/reports/view/Pages/PlayerReports/PlayerHistory';
import SportBets from 'features/reports/view/Pages/PlayerReports/SportBets';
import { MyDebtAccount, HowToStart, FlatPage } from 'features/finance/desktop';

import { Tree } from 'features/tree';

import Header from 'components/Header/desktop';
import Subheader from 'components/Subheader/desktop';
import Sidebar from 'components/Sidebar/desktop';

import Auth from 'features/Auth/desktop';

import './App.scss';

const App = () => {
  const b = block('app');
  const locale = useSelector(state => state.locale.locale);
  const isAuth = useSelector(state => state.auth.isAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authAction.checkAuth());
  }, [dispatch]);
  return (
    <ModalProvider>
      <div className={b()}>
        <Notify />

        {isAuth ? (
          <>
            <div className={b('wrapper')}>
              <div className={b('wrapper-header')}>
                <Header />
              </div>

              <div className={b('wrapper-sidebar')}>
                <Sidebar />
              </div>

              <main className={b('wrapper-main')}>
                <Subheader />

                <div className={b('wrapper-content')}>
                  <Switch>
                    <Route exact key="/users" path="/users" component={Users} />
                    <Route key="/users/:tab" path="/users/:tab" component={Users} />

                    <Route
                      exact
                      key="/reports/financial-reports/general"
                      path="/reports/financial-reports/general"
                      component={General}
                    />
                    <Route
                      exact
                      key="/reports/financial-reports/cash"
                      path="/reports/financial-reports/cash"
                      component={Cash}
                    />
                    <Route
                      exact
                      key="/reports/player-reports/detail-by-player"
                      path="/reports/player-reports/detail-by-player"
                      component={DetailByPlayer}
                    />
                    <Route
                      exact
                      key="/reports/player-reports/player-history"
                      path="/reports/player-reports/player-history"
                      component={PlayerHistory}
                    />
                    <Route
                      exact
                      key="/reports/player-reports/sport-bets"
                      path="/reports/player-reports/sport-bets"
                      component={SportBets}
                    />

                    <Route
                      exact
                      key="/finance/my-debt-account"
                      path="/finance/my-debt-account"
                      component={MyDebtAccount}
                    />

                    <Route exact key="/finance/how-to-start" path="/finance/how-to-start" component={HowToStart} />
                    <Route
                      exact
                      key="/finance/how-to-start/:page"
                      path="/finance/how-to-start/:page"
                      component={FlatPage}
                    />

                    <Redirect from="/" to="/users/all" />
                  </Switch>
                  <div className={b('user-tree')}>
                    <Tree />
                  </div>
                </div>
              </main>
            </div>
          </>
        ) : (
          <Switch>
            <Route exact key="/" path="/" component={Auth} />
            <Redirect to="/" />
          </Switch>
        )}
      </div>
    </ModalProvider>
  );
};

export default App;
