export class AuthConverter {
  convertUserData(data) {
    const convertedData = {
      id: data.id,
      email: data.email,
      currency: data.currency,
      firstName: data.first_name,
      lastName: data.last_name,
      role: +data.role,
      isPartner: data.is_partner,
      typePartnerPayment: data.type_partner_payment,
      isWithdrawalAccess: data.is_withdrawal_access,
      username: data.username,
    };
    return convertedData;
  }

  convertBalance(data) {
    const convertedData = {
      balance: data.balance,
      bonusBalance: +data.bonus_balance,
      currency: data.currency,
      isBanned: data.is_banned,
    };
    return convertedData;
  }
}
