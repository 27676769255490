import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import block from 'bem-cn';

import './Table.scss';
import TableRow from './TableRow/TableRow';
import TableHead from './TableHead/TableHead';

const b = block('reports-table-general');

const Table = ({ thItems, trItems, needTable = false }) => {
  const { tab } = useParams();

  const locale = useSelector(state => state.locale.locale);
  const rows = useMemo(
    () => trItems?.map(item => <TableRow needTable={needTable} items={item} />),
    [trItems, needTable],
  );

  return (
    <div className={b()}>
      <table className={b('table')}>
        <TableHead items={thItems} locale={locale} />
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  needTable: PropTypes.bool,
};

export default Table;
