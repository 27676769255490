import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import Spinner from 'components/Spinner/view/desktop';

import FilterBlock from './FilterBlock';
import Table from 'components/TableWithPagination/Table/Table';
import './DetailByPlayer.scss';

const b = block('detail-by-player');

const DetailByPlayer = ({ isMobile }) => {
  const statisticPlayer = useSelector(state => state.reports.statisticPlayer, shallowEqual);
  const { actionProcessing } = useSelector(state => state.reports, shallowEqual);

  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);

  const onItemsOnPageChanged = newItemsOnPage => {
    setCount(newItemsOnPage);
    setPage(0);
  };

  return (
    <div className={b({ isMobile })}>
      <Spinner isLoading={actionProcessing} />
      <FilterBlock count={count} currentPage={page} onPageClick={setPage} isMobile={isMobile} />
      {statisticPlayer.userStatistic.length !== 0 && (
        <Table
          currentPage={page}
          onPageClick={setPage}
          onItemsOnPageChanged={onItemsOnPageChanged}
          thTdItems={statisticPlayer.userStatistic}
          totalCount={statisticPlayer.details.totalCount}
          isMobile={isMobile}
          tdAlign="left"
          needTable
        />
      )}
    </div>
  );
};

export default DetailByPlayer;
